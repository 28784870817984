<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 395px">
        <field
          name="delegacion_id"
          widget="m2o"
          label="Deleg."
          width="165px"
          style="top: 9px; left: 10px"
          :readonly="mode == 'edit'"
        />
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Documento"
          width="110px"
          style="top: 9px; left: 189px"
        />
        <field
          name="fecha"
          widget="date"
          type="date"
          default="today"
          label="Fecha"
          width="100px"
          style="top: 9px; left: 307px"
        />
        <field
          name="almacen_id"
          widget="m2o"
          label="Almacen"
          width="235px"
          style="top: 9px; left: 415px"
          :readonly="mode == 'edit'"
          :filter="
            formData.delegacion_id
              ? [['delegacion_id', '=', formData.delegacion_id.codigo]]
              : null
          "
          @change="onChangeAlmacen"
        />
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top: 55px; height: 340px"
          :buttons="['Entradas', 'Líneas']"
        >
          <div class="tab">
            <field
              ref="documentos"
              name="documentos"
              widget="handsontable"
              :height="295"
              :width="655"
              :minRows="12"
              style="top: 0px; left: 0px; width: 660px"
              :readonly="!formData.almacen_id"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado',
                },
                { name: 'ndoc', header: 'Nº', readOnly: true },
                {
                  name: 'fecha',
                  type: 'date2',
                  header: 'Fecha',
                  readOnly: true,
                },
              ]"
            />
          </div>
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              widget="handsontable"
              :height="295"
              :width="655"
              :minRows="12"
              style="top: 0px; left: 0px; width: 660px"
              customprop="docs_customprop"
              readonly
              :columns="[
                { name: 'ean13', header: '|||||||||', readOnly: true },
                {
                  name: 'articulo_id',
                  header: 'Cód.',
                  readOnly: true,
                  noOpen: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  showCode: false,
                },
                { name: 'nombre', header: 'Artículo', readOnly: true },
                {
                  name: 'unidades_real',
                  header: 'Unid. reales',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_real',
                  header: 'Peso. reales',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'unidades_teorico',
                  header: 'Unid. teórico',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function () {
    var self = this;
    return {
      title: "Regularización de inventario",
      dbAdapter: "regularizacion",
      primary: "id",
      sequence: { name: "regularizacion" },
      defaultData: {
        delegacion_id: self.app.config.delegacion_defecto || null,
        almacen_id: self.app.config.almacen_defecto || null,
        fecha: new Date().yyyymmdd(),
        wt: 0,
      },
      fields: ["wt"],

      documentos_orig: [],
    };
  },
  methods: {
    onChangeAlmacen() {
      var self = this;
      if (self.formData.almacen_id?.codigo) self.actualizarDocumentos();
      else self.$set(self.formData, "documentos", []);
    },
    actualizarDocumentos() {
      var self = this;
      if (!self.formData.almacen_id?.codigo) return;
      window.DB.getList("entrada_inventario", {
        filter: ["almacen_id", "=", self.formData.almacen_id?.codigo],
        fields: [
          "ndoc",
          "fecha",
          "seleccion",
          { name: "familia_id", fields: ["codigo", "nombre"] },
          { name: "seccion_id", fields: ["codigo", "nombre"] },
          { name: "proveedor_id", fields: ["codigo", "nombre_comercial"] },
          { name: "marca_id", fields: ["codigo", "nombre"] },
          {
            name: "lineas",
            fields: ["ean13", "descripcion", "unidades", "peso", "precio"],
          },
        ],
      }).then(function (res) {
        self.documentos_orig = res.data;
        self.$set(self.formData, "documentos", res.data);
      });
    },
    actualizarLineas() {
      var self = this;
      let doc_ids = self.formData.documentos.filter(x => parseInt(x.sel || 0)).map(x => x.id);

      //self.$set(self.formData, "lineas", res.data);
    },
  },
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          if (
            self.mode != "edit" &&
            self.mode != "new" &&
            self.mode != "search"
          )
            return;
          self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
          self.$emit("change", "wt", "edit");
          if (self.mode == "search") self.setFilter(self.getFilter());
        }
      }
    );

    self.$refs.lineas.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];

          if(prop == 'sel' && oldVal != newVal) {
            self.actualizarLineas();
          }
        });
      }
    );
  },
};
</script>