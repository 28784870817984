var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form'),_c('section',[_c('article',{staticStyle:{"height":"395px"}},[_c('field',{staticStyle:{"top":"9px","left":"10px"},attrs:{"name":"delegacion_id","widget":"m2o","label":"Deleg.","width":"165px","readonly":_vm.mode == 'edit'}}),_c('field',{staticStyle:{"top":"9px","left":"189px"},attrs:{"name":"ndoc","widget":"char","searchable":"","label":"Nº Documento","width":"110px"}}),_c('field',{staticStyle:{"top":"9px","left":"307px"},attrs:{"name":"fecha","widget":"date","type":"date","default":"today","label":"Fecha","width":"100px"}}),_c('field',{staticStyle:{"top":"9px","left":"415px"},attrs:{"name":"almacen_id","widget":"m2o","label":"Almacen","width":"235px","readonly":_vm.mode == 'edit',"filter":_vm.formData.delegacion_id
            ? [['delegacion_id', '=', _vm.formData.delegacion_id.codigo]]
            : null},on:{"change":_vm.onChangeAlmacen}}),_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"55px","height":"340px"},attrs:{"wt":_vm.formData.wt,"buttons":['Entradas', 'Líneas']}},[_c('div',{staticClass:"tab"},[_c('field',{ref:"documentos",staticStyle:{"top":"0px","left":"0px","width":"660px"},attrs:{"name":"documentos","widget":"handsontable","height":295,"width":655,"minRows":12,"readonly":!_vm.formData.almacen_id,"columns":[
              {
                name: 'sel',
                header: 'Sel.',
                widget: 'checkbox',
                help: 'Seleccionado',
              },
              { name: 'ndoc', header: 'Nº', readOnly: true },
              {
                name: 'fecha',
                type: 'date2',
                header: 'Fecha',
                readOnly: true,
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"lineas",staticStyle:{"top":"0px","left":"0px","width":"660px"},attrs:{"name":"lineas","widget":"handsontable","height":295,"width":655,"minRows":12,"customprop":"docs_customprop","readonly":"","columns":[
              { name: 'ean13', header: '|||||||||', readOnly: true },
              {
                name: 'articulo_id',
                header: 'Cód.',
                readOnly: true,
                noOpen: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
                showCode: false,
              },
              { name: 'nombre', header: 'Artículo', readOnly: true },
              {
                name: 'unidades_real',
                header: 'Unid. reales',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso_real',
                header: 'Peso. reales',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'unidades_teorico',
                header: 'Unid. teórico',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              } ]}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }